<template>
    <v-dialog class="dialog" :value="showDialog" :persistent="false" width="60vw" @input="closeDialog">
        <v-card>
            <v-card-title class="headline">
                <span><i class="flaticon-lock-1 mr-2"></i>Сменить пароль</span>
            </v-card-title>
            <v-card-text>
                <ValidationObserver ref="observer">
                    <form class="request-form">
                        <ValidationProvider name="old_password" rules="required|min:4" v-slot="{ errors }">
                            <div class="form-group">
                                <label class="request-form__label" for="old_password">Старый пароль <i class="request">*</i></label>
                                <input type="password" class="form-control" id="old_password" v-model="form.old_password" name="old_password" minlength="4" required>
                            </div>
                            <span v-if="errors" class="d-block my-2">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider name="new_password" rules="required|min:10|password" v-slot="{ errors }">
                            <div class="form-group">
                                <label class="request-form__label" for="new_password">Новый пароль <i class="request">*</i></label>
                                <input type="password" class="form-control" id="new_password" v-model="form.new_password" name="new_password" minlength="4" required>
                            </div>
                            <span v-if="errors" class="d-block my-2">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider name="confirm_password" rules="required|min:10|password|confirmed:new_password" v-slot="{ errors }">
                            <div class="form-group">
                                <label class="request-form__label" for="confirm_password">Подтверждение нового пароля <i class="request">*</i></label>
                                <input type="password" class="form-control" id="confirm_password" v-model="form.confirm_password" name="confirm_password" minlength="4" required>
                            </div>
                            <span v-if="errors" class="d-block my-2">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </form>
                </ValidationObserver>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
                <CButton @click="requestChangePassword()" color="success">
                    <i class="mr-2 fa fa-save"></i> <span>Сохранить</span>
                </CButton>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from '@/api';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import {showSuccess} from "@/lib/swal2";

export default {
    name: "ChangePasswordModal",
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    mixins: [
        EntityModalMixin
    ],
    data: () => ({
        form: {
            old_password: '',
            new_password: '',
            confirm_password: '',
        },
    }),
    methods: {
        async requestChangePassword() {
            const isValid = await this.$refs.observer.validate()
            if (isValid) {
                const {data: {status, message}} = await api.account.changePassword(this.form);
                showSuccess(message)
                this.$emit('close')
            }
        }
    },
}
</script>

<style scoped>

</style>
